import i18next from "i18next";
import { useState } from 'react';
import {sanitize} from "dompurify";
import { useTranslation } from "react-i18next";
import { toObj, toStr } from '../../libs/libType';
import i18n from "../../poc/translate/i18n";

export const langIsEn = l => l === 'en'? 1: 0;
export const isEn2Lang = isE => isE? 'en': 'zh';
export const validLang = l => (l === 'en')? 'en': 'zh';
export const en2t = (isEn, e, c) => toStr(isEn? e||c: c||e);
export const lang2t = (lang, e, c) => toStr(langIsEn(lang)? e||c: c||e);

//base on <SwitchLanguage> 
export const useUILang = () => {
    const {t} = useTranslation(['schema']); // dummy call to listen lang state change
    const t2 = (tcode, isE = true) => t(tcode, {lng: isEn2Lang(isE)});
    const t3 = tcode => {
        const transTxt = t(tcode); // translate text from en/zn
        const noTransTxt = t(tcode, {lng: 'tmp'}); // if no translated text, use text from tmp namespace
        return (transTxt===tcode)?('['+tcode+'] '+ noTransTxt):transTxt;
    };
    const tht = tcode => <div dangerouslySetInnerHTML={{__html: sanitize(t(tcode))}}></div>;

    const isUIEn = langIsEn(i18next.language);
    const lang = isEn2Lang(isUIEn);
    const setLang = l => {
        const L = validLang(l);
        window.localStorage.setItem('Lang', L);
        i18n.changeLanguage(L);
        window.uiLang = L;
        setOnTrustLang(langIsEn(L)? 'en': 'zh-hant', 'setLang'); //EX-1691

    };
    const ut = (e, c) => toStr(isUIEn? (e||c): (c||e));
    return [t, isUIEn, lang, setLang, ut, t2, t3, tht];
};

export const useCtnLang = (init) => {
    const [L, _setL] = useState(init);
    const en = langIsEn(L);
    const lang = isEn2Lang(en);
    const setL = l => _setL(validLang(l));
    const flipL = () => _setL(en? 'zh': 'en');
    const ct = (e, c) => toStr(en? (e||c): (c||e));
    return [en, ct, flipL, lang, setL];
};

const setOnTrustLang = (oneTrustLang, caller) => { //EX-1691
    try{
        const trust1 = window.OneTrust; 
console.log('otdebug:setOnTrustLang()', oneTrustLang, caller, trust1);  
//conflict with bootstrip!!!  
//document.documentElement.lang = oneTrustLang;//navigator.language; 
//conflict with bootstrip!!!  
//document.documentElement.setAttribute('lang', oneTrustLang); //navigator.language); 
        window.OTLang = oneTrustLang;
//console.log('setOnTrustLang() called', {caller, trust1, oneTrustLang}); //todo set onetrust lang    
        if(trust1 && oneTrustLang){
            trust1.changeLanguage?.(oneTrustLang);
            return;
        }
    }catch(e){ 
        console.error('otdebug:setOnTrustLang()'); console.error(e); console.log(window.uiLang); 
    } 
    setTimeout(()=>{ setOnTrustLang(oneTrustLang, 'timer'); },10);
}

window.OptanonWrapper = function(){  //EX-1691
    console.log('otdebug: OptanonWrapper()');  
    setOnTrustLang(window.OTLang, 'OptanonWrapper'); 
}; 
console.log('otdebug: window.OptanonWrapper():', window.OneTrust, window.OptanonWrapper); //EX-1691