import React, { useEffect, useMemo, useState } from 'react';

import {stopEvent} from '../../libs/libUI';
import { _ATRoot } from '../../consts/ATConstReact';

import { aryLen, isFunc, isObj, objEntries, objKeys, objLen, objVals, toAry, toInt, toObj, toStr } from '../../libs/libType';
import { ReduxBind } from '../../saga/ReduxState';
import { clearRemes, exerMapNextMedia, exerUniQIds } from '../../consts/ATValidateExer';
import { useChain, useChainFunc } from '../utils/useChain';
//--------------------------------------------------------
import {Button, } from "react-bootstrap";
import SharedModalHeader from '../../AppExPFUser/_components/CpSharedModalHeader';
import { CpSettingModalTitle, CpSettingModalSection, CpSettingModalTabContainer } from '../../AppExPFUser/_components/CpSettingModalTabCps';
import { useUILang } from '../utils/useUILang';
import { ent2DropdownItem } from '../../AppExPFUser/_components/CpDropdown';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpActionButton from '../../AppExPFUser/_components/CpActionButton';
import { BtnPopDev, preJS } from '../AppUtil';
import CpSharedModal from '../../AppExPFUser/_components/CpSharedModal';
import { useCaches } from '../utils/useCaches';
import { clsQLang } from '../ATQtnEdit/PageATQtnEdit';
import { langCodeCt, langCodeEn } from '../../consts/ATConsts';
import { LayerLocalMedia } from '../ATMediaPool/LayerLocalMedia';
import LayerUpMedia, { upLocalMedias } from '../../AppExPFUser/EPExercise/LayerEMediaUp';
import { asyncApiCallLoad_ } from '../../libs/awsFuncs';
import { toIdStr, toUniIdAry } from '../../consts/ATValidate';
import { expf2 } from '../../consts/ATSysQType';
import CpExStep from './CpExStep';
import LayerEditReme from './LayerEditReme';
import { splitSMId } from '../../consts/ATValidateMetaSet';
import { _ATMetaTransByCode, _ATMetaTypeCodes, _ATMetaTypes, _REME_METATYPES } from '../../consts/ATMetaTypes';
import CpExReme from './CpExReme';
import { RemeLvPre, RemeLvPro, RemeLvSame } from '../../consts/ATValidateReme';
import { mkEQSumFake } from './TabExerSetting';
import LayerEditRule from './LayerEditRule';
import { mapTMSets } from '../ATQtnEdit/PageATQtns';
import { normalizeNextRule, validateNextRule } from '../../consts/ATValidateNext';
import { hasErr } from '../utils/useEditDraftPub';
// follow poc
import CpDropdown from '../../AppExPFUser/_components/CpDropdown';
import { _AST_PUBLISHED } from '../../consts/ATConstsAssignment';
import CpTips from '../../AppExPFUser/EPReports/_component/CpTips';
import { atWarnDot, atWarnNoDot } from '../AppUtil';
import { onlyEndSMIdEnts, onlyPubSMIds } from '../../AppExPFUser/EPReports/data/dataUtil';
import { TART_reme } from '../../AppExPFUser/EPReports/PageReports';

export const TabExerNextStep = ReduxBind( props => {
    const {ex, setFields } = props;
    const ENexts = toAry(ex?.ENexts);
    const ERemes = toAry(ex?.ERemes);
    const [addEContent, replaceContent, deleteContent, moveBlock, insertBlock] = useChain(setFields, 'ENexts'); 
    const [addEReme, replaceEReme, deleteEReme, ] = useChain(setFields, 'ERemes'); 
    return <TabExerNextStepBase className='TabExerNextStep' {...{...props, 
        ENexts, addEContent, replaceContent, deleteContent,  
        ERemes, addEReme, replaceEReme, deleteEReme, }}/>;
});

export const TabAsmNextStep = ReduxBind( props => {
    const { dispatch } = props;
    const {asm, ex, reload} = props;
    const assignId = toIdStr(asm?.assignId);
    const asmPub = (asm.AState === _AST_PUBLISHED);
    const lockReme = (!asm) || asmPub;

    const { mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias } = props;
    const [onAddMedia, setOnAddMedia] = useState();
    const [upMsg, setUpMsg ] = useState();

    const initRemes = ex?.ERemes;
    const [_ERemes, _setERemes] = useState();
    useEffect(() => { _setERemes(toAry(initRemes)); }, [initRemes]);
    const setERemes = async fov => {
        const ERemes = isFunc(fov)? fov(_ERemes): fov;
        _setERemes(ERemes);
        const [res, err] = await asyncApiCallLoad_(dispatch, '/putAssignmentUpdate', {assignId, ERemes});
        reload();
    };

    const initNexts = ex?.ENexts;
    const [_ENexts, _setENexts] = useState();
    useEffect(() => { _setENexts(toAry(initNexts)); }, [initNexts]);
    const setENexts = async fov => {
        const newNexts = isFunc(fov)? fov(_ENexts): fov;
        _setENexts(newNexts);
        await SaveAsmNext(newNexts);
        reload();
    };
    const SaveAsmNext = async ENexts => {
        const localMids = exerMapNextMedia(ENexts, []);
        const mMap = await upLocalMedias(dispatch, localMids, getMediaDLs, setUpMsg);
        exerMapNextMedia(ENexts, mMap);
        const [res, err] = await asyncApiCallLoad_(dispatch, '/putAssignmentUpdate', {assignId, ENexts});
    };

    const ENexts = toAry(_ENexts);
    const ERemes = toAry(_ERemes);
    const [addEContent, replaceContent, deleteContent, moveBlock, insertBlock] = useChainFunc(setENexts); //useChain(setFields, 'ENexts'); 
    const [addEReme, replaceEReme, deleteEReme, ] = useChainFunc(setERemes); 
    return <>
        <TabExerNextStepBase className='TabAsmNextStep' {...{...props, asmPub, lockReme, setOnAddMedia, LocalMediaDL, addLocalMedias, 
            ERemes, addEReme, replaceEReme, deleteEReme, 
            ENexts, addEContent, replaceContent, deleteContent }}/>
        <LayerLocalMedia clickClose={()=>setOnAddMedia(0)} {...{...onAddMedia, setOnAddMedia, mediaDLs, getMediaDLs}}/>
        <LayerUpMedia {...{head:'Saving Assignment', upMsg}} />      
    </>
});

export const ex2RQnum = (ex, allSubjMs) => {
    if(!isObj(ex)) return 0;
    const {EMSubjId, EMetaDisplays} = toObj(ex);
    const smids = toUniIdAry([EMSubjId, ...toUniIdAry(EMetaDisplays)]);

    const jMap = Object.fromEntries(toAry(allSubjMs).map(j => [j.metaSetId+','+j.metaId, j]));
    const exCfgs = smids.map(i => jMap[i]?.cfg).filter(c => c); //(subjCfgs).filter(cfg => cfg);
    const qnum = toInt(exCfgs[0]?.numQ); 
console.log('ex2RQnum()', {qnum, smids, jMap, exCfgs, EMSubjId, EMetaDisplays, allSubjMs, ex});
    return  qnum;
}

const ex2SubjMs = (ex, allSubjMs) => {
    if(!isObj(ex)) return {};
    const smids = toUniIdAry([ex.EMSubjId, ...toUniIdAry(ex.EMetaDisplays)]);
    const alljs = Object.fromEntries(toAry(allSubjMs).map(j => [j.metaSetId+','+j.metaId, j]));
    const ejs = smids.map(i => alljs[i]).filter(j => j);
//console.log('ex2SubjMs', ejs, smids, alljs);
    return ejs;
};

const TabExerNextStepBase = ReduxBind( props => {
    const {dispatch, asmPub, ex, showEn, clickLang, lock, isAT, hasEn, hasCt, 
        qtns, useCacheQtns, tagMSets, useTagMSets, setField,
        ERemes, addEReme, replaceEReme, deleteEReme,
        ENexts, addEContent, replaceContent, deleteContent } = props;
    const {setTab, setRType} = props;
    const [fieldErrs, setFieldErrs] = useState({});
    const lockReme = lock || props.lockReme; 

    const myUId = props.userId();
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();       
    const [ cache2d, useGetCache ] = useCaches(props);
    const [ eNextList, setENextList ] = useState(_NEXTTYPE_ALL);

    const [edNext, setEdNext] = useState({});
    const beforeUpdate = (e, func, data) => {
        stopEvent(e);
        const r = normalizeNextRule(data);
        const fes = validateNextRule(r, {showEn});
        if (!hasErr(fes)) { 
            func && func();
            return;
        };
        setFieldErrs(fes);
    };

    /*const beforeAdd = (e) => {
        stopEvent(e);
        const r = normalizeNextRule(edNext);
        const fes = validateNextRule(r, {showEn});
        if (!hasErr(fes)) { 
            clickAdd();
            return;
        };
        setFieldErrs(fes);
    };*/
    
    const clickAdd = () => { addEContent(edNext); clickCloseAdd(); };
    const [open, setOpen] = useState(false);
    const clickCloseAdd = () => { setOpen(false); }
    const clickOpenAdd = () => { 
        const data = initEdNext(myUId);
        data.ruleNameEn = t2('new-rule');
        data.ruleNameCt = t2('new-rule', false);
        setFieldErrs({});
        setEdNext(data);
        setOpen(true);
    };
    const clickDelI = idx => e => { stopEvent(e); deleteContent(idx); };
    
    const [edRIdx, setEdRIdx] = useState(-1);
    const [edReme, setEdReme] = useState({});
    const clickAddEx = () => { addEReme(edReme); clickCloseEx(); };
    const [openEx, setOpenEx] = useState(false);
    const clickCloseEx = () => { setOpenEx(false); }
    const clicNewEx = () => { setEdReme(initEdReme(myUId)); setEdRIdx(-1); setOpenEx(true); }
    const clickDelERemeI = idx => e => { stopEvent(e); deleteEReme(idx); };

    const uniQIds = useMemo(() => exerUniQIds(ex), [ex]);
    useCacheQtns(uniQIds);
    const EQSum = useMemo(() => {
        //console.log('TabExerNextStepBase EQSumEff', (props.EQSum? 'Given': 'No')+' EQSums', ex, qtns, );
        if(!props.EQSum) console.warn('TabExerNextStepBase cannot get EQSum from parent CP');
        return mkEQSumFake(ex, qtns);
    }, [ex, qtns, props.EQSum]);
    const [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, EQMetas, EQMetaPres_, EQMetaPros_, minQs, ] = toAry(EQSum);

    const [qSMIds, qSMIdPairs, remeMSIds] = useMemo(() => {
        const qSMIds = toUniIdAry([...toAry(EQMetas), ...toAry(EQMetaPres_), ...toAry(EQMetaPros_)]); 
        const qSMIdPairs = toUniIdAry(qSMIds).map(splitSMId);
        const remeMSIds = toUniIdAry(qSMIdPairs.map( sm => sm[0]));
        return [qSMIds, qSMIdPairs, remeMSIds];
    }, [EQSum]);
    
    const msets = useTagMSets(remeMSIds);

    useEffect( () => clearRemes(msets, ERemes, setField('ERemes')), [msets, ERemes]);

    const remeMSets = useMemo(() => Object.fromEntries(objVals(msets).filter(s => toAry(_REME_METATYPES).includes(s.MSType)).map(s => [s.metaSetId, s])), [msets]);
    const [TMSets, SMId2d] = useMemo(() => mapTMSets(qSMIdPairs, remeMSets), [remeMSets]); 

    const subjMetas = useGetCache('subjMetas', '*');
    const remeQNum = useMemo(() => ex2RQnum(ex, subjMetas), [ex, subjMetas]);

    const EQMetaPres = useMemo(() => onlyPubSMIds(msets, EQMetaPres_), [msets, EQMetaPres_]);
    const EQMetaPros = useMemo(() => onlyPubSMIds(msets, EQMetaPros_), [msets, EQMetaPros_]);
    const EQMetaSames = useMemo(() => [].concat(...toAry(onlyEndSMIdEnts(msets, qSMIdPairs, 0)).map(e => toAry(e[1]))), [msets, qSMIdPairs, remeMSIds]);  
    const remesSMIds = useMemo(() => toUniIdAry([...toAry(EQMetaSames), ...toAry(EQMetaPres), ...toAry(EQMetaPros)]), [EQMetaSames, EQMetaPres, EQMetaPros,]);

    //sum msid By reme rules===
    //const remeM2d = useMemo(() => toAry(ERemes).map(r => rem2metas(EQMetas, EQMetaPres, EQMetaPros, r)), [EQSum, ERemes]);
    //const edRemeMs = useMemo(() => rem2metas(EQMetas, EQMetaPres, EQMetaPros, edReme), [EQSum, edReme?.MSetId, edReme?.rmLv]);
    //const remesSMIds = useMemo(() => mixRemes(remeM2d, edRemeMs, edRIdx), [remeM2d, edRemeMs, edRIdx]);

    const rmstr = useMemo(() => remeQQStr(ex, remeQNum, remesSMIds,), [ex, remeQNum, remesSMIds]); 
    const remeQCnts = useMemoAsync(async () => await findRemeQtns(dispatch, ex, remeQNum, remesSMIds, 'TabExerNextStepBase', rmstr), [rmstr, remeQNum]);
    
    const canAddReme = expf2 && remeQNum && objLen(TMSets) && objLen(remeMSets); 
    const hasRemeReport = 1; // !asm ?
    const showReportBtn = asmPub && hasRemeReport;
    const warningMsg = !asmPub && (ERemes.length > 0); // parent has called toAry

    const clickFollowReport = () => { setTab('report'); setRType(TART_reme); };

    const MTDDIs = useMemo(() => {
        const mtids = objKeys(TMSets); 
        return ent2DropdownItem(_ATMetaTypeCodes()
            .filter(tc => mtids.includes(tc))
            .map(tc => [tc, t2(_ATMetaTransByCode(tc), showEn)])
        );
    }, [TMSets, showEn]); 

    const nextListDDI = useMemo(() => ent2DropdownItem([
        [_NEXTTYPE_ALL, t("exercise-edit-tab-next-steps-title-all")],
        [_NEXTTYPE_RES, t("exercise-edit-tab-next-steps-title-rule")],
        canAddReme && [_NEXTTYPE_REME, t("exercise-edit-tab-next-steps-title-follow-up-exercise")],
    ].filter(e => e)), [canAddReme, uiEn]);

    const IProps = {...props, isAT, useGetCache, cache2d, EQSum, TMSets, MTDDIs, addEContent, replaceContent, clickDelI, fieldErrs, setFieldErrs, beforeUpdate};
    const RProps = {...IProps, lockReme, ERemes, edReme, edRIdx, remeQCnts, remeQNum,
        addEReme, replaceEReme, clickDelERemeI, 
        setEdRIdx, setEdReme};
        //edRIdx, setEdRIdx, edReme, setEdReme};
    useEffect(() => {
        objEntries(msets).forEach(([msid, mset]) => {
            const mPubs = objEntries(mset.metas).map(([mid, m]) => m.pub && mid).filter(i => i).sort().join(', ');
            const mUnPubs = objEntries(mset.metas).map(([mid, m]) => (!m.pub) && mid).filter(i => i).sort().join(', ');
            //console.log('mset '+msid, {mUnPubs});
            //console.log('mset '+msid, {mPubs});
        });
    }, [msets]);

    return <>
       <CpSettingModalTabContainer className='TabExerNextStepBase' paddingX={0}>      
            <CpSettingModalSection hideBorder={true} paddingX={0}>
                {isAT?<div className='flexRowStartFit f14'>
                    {hasEn? <div className={'w '+clsQLang(showEn)} onClick={clickLang(langCodeEn)} >En</div>: ''}
                    {hasCt? <div className={'w '+clsQLang(!showEn)} onClick={clickLang(langCodeCt)} >繁中</div>: ''}
                </div>:''}
                <div className="w-100 d-flex justify-content-between flex-column gap-3 flex-md-row">
                    <span style={{ width: "250px" }} className={"d-flex w-sm-100"}>
                        <CpDropdown {...{id: 'DDNextList', items: nextListDDI, idx: eNextList, cb: setENextList}} />
                    </span>                    
                    {(!lock) && <span className="d-flex">
                        <CpActionButton hiddenText={false} onClick={clickOpenAdd} iconPath={IconList.general.addRule} 
                            title={t(expf2? "exercise-edit-tab-next-steps-add-rules": "exercise-edit-tab-next-steps-add-rules")}
                            className={"btn exercise-action-btn gap-2 rounded semi-bold user-select-none mx8"} />
                        {(canAddReme && !asmPub)? <CpActionButton hiddenText={false} onClick={clicNewEx} iconPath={IconList.general.addExercise} 
                            title={t("exercise-edit-tab-next-steps-add-exercise")}
                            className={"btn exercise-action-btn gap-2 rounded semi-bold user-select-none mx8"} />: ''}
                    </span>}                    
                </div>
                <CpSettingModalTitle className={"semi-bold fs-6 user-select-none d-flex gap-2 flex-wrap w-100 justify-content-between align-items-center"}>
                    <span>{t("exercise-edit-tab-next-steps-after-publishing-the-results-to-students")}:</span>
                </CpSettingModalTitle>

                {[_NEXTTYPE_ALL, _NEXTTYPE_RES].includes(eNextList)?<>
                    <CpSettingModalTitle className={"semi-bold fs-6 user-select-none d-flex gap-2 flex-wrap w-100 justify-content-between align-items-center text-decoration-underline"}>
                        <span>{t("exercise-edit-tab-next-steps-title-rule")}</span>
                    </CpSettingModalTitle>                
                    <div className={"d-flex flex-column w-100 gap-3"}>
                        {toAry(ENexts).map((ENx, idx) => <CpExStep key={idx} index={idx + 1} {...{...IProps, ENx, idx}}/>)}
                    </div>
                </>:''}


<BtnPopDev txt={"smids"} >
    {preJS({EQMetas, EQMetaSames})}
    {preJS({EQMetaPres, EQMetaPres_})}
    {preJS({EQMetaPros, EQMetaPros_})}
    {preJS({remesSMIds})}
</BtnPopDev>
<BtnPopDev txt={"canReme"} >{preJS({
    rmstr,
    canAddReme, 
    remeQCnts,
    msids: objKeys(msets),
    remeMSTs: objKeys(TMSets),
    remeMSIds: objKeys(remeMSets),
}, 3)}</BtnPopDev>
<BtnPopDev txt={"reme"} >{preJS({edRIdx, EQSum}, 3)}</BtnPopDev>                
            {[_NEXTTYPE_ALL, _NEXTTYPE_REME].includes(eNextList)?<>
                    {canAddReme?<><CpSettingModalTitle className={"semi-bold fs-6 user-select-none d-flex gap-2 flex-wrap w-100 justify-content-between align-items-center "}>
                            <div className="d-flex gap-2 align-items-center text-decoration-underline">
                            {t("exercise-edit-tab-next-steps-title-follow-up-exercise")}
                            {/*<CpIco src={IconList.general.tips} />*/}
                            <CpTips autoPos={1}>{atWarnDot("warning.warning_follow_up_exercise_info",t)}</CpTips>
                            </div>
                            {warningMsg?atWarnNoDot("warning.warning_follow_up_before_published",t):""}
                            {showReportBtn?<CpActionButton hiddenText={true} onClick={clickFollowReport} iconPath={IconList.assignment.reports} 
                            title={t("exercise-edit-tab-next-steps-view-report")} 
                            className={"btn exercise-action-btn gap-2 rounded semi-bold user-select-none mx8"} />: ''}
                        </CpSettingModalTitle>
                        <div className={"d-flex flex-column w-100 gap-3"}>
                        {toAry(ERemes).map((REx, idx) => <CpExReme key={idx} index={idx + 1} {...{...RProps, REx, idx}} />)}
                        </div>
                    </>:''}
                </>:''}
            </CpSettingModalSection> 
 
            <CpSharedModal className={"modal-lg"} show={open} scrollable={true} 
                header={<SharedModalHeader iconPath={IconList.general.add} handleClose={clickCloseAdd}
                    title={t(expf2? "exercise-edit-tab-next-steps-add-rules": "exercise-edit-tab-next-steps-add-rules")} />}
                footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                    <Button variant="gray-body-color w-100 border" onClick={clickCloseAdd}>{t("cancel")}</Button>
                    <Button variant={`exercise-third-btn btn-block w-100`} onClick={(e)=>beforeUpdate(e, clickAdd, edNext)}>{t("add")}</Button>
                    {/*<Button variant={`exercise-third-btn btn-block w-100`} onClick={beforeAdd}>{t("add")}</Button>*/}
                </div>} >
                <CpSettingModalTabContainer gap={3}><CpSettingModalSection>
                    <LayerEditRule key={'newNextStep'} index={0} {...IProps} ENx={edNext} setENx={setEdNext} fieldErrs={fieldErrs}/>
                </CpSettingModalSection></CpSettingModalTabContainer>
            </CpSharedModal>

            {openEx? <LayerEditReme key={'newNextStep'} index={0} {...{...RProps, isAdd:1, clickCloseEx, clickSaveEx: clickAddEx, REx: edReme, setREx: setEdReme }} />: ''}

        </CpSettingModalTabContainer>   
    </>;
});

const _NEXTTYPE_RES = 'nxt_res';
const _NEXTTYPE_REME = 'nxt_reme';
const _NEXTTYPE_ALL = 'nxt_all';

const remeQQStr = (ex, remeQNum, remeSMIds, ) => {
    const { EId, ELangEn, ELangCt, EModeScroll, EModeSlide, } = toObj(ex);
    return [EId, ELangEn, ELangCt, EModeScroll, EModeSlide, toInt(remeQNum), toUniIdAry(remeSMIds).sort().join(';')].map(s => toStr(s)).join('| ');
};

const findRemeQtns = async (dispatch, ex, remeQNum, remeSMIds, caller, qqstr) => {
    console.log('findRemeQtns()', {qqstr});
    if(!isObj(ex)) return;
    if(!remeQNum) return {};
    const { ELangEn, ELangCt, EModeScroll, EModeSlide, EId } = toObj(ex);
    const fields = {skipEId:EId, remeQNum, remeSMIds, ELangEn, ELangCt, EModeScroll, EModeSlide, caller};
    const [res, err] = await asyncApiCallLoad_(dispatch, '/getRemeQuestions', fields);
    return toObj(res.remeQCnts);
};

const initEdNext = (modUid) =>  ({
    ruleNameEn:'New Rule', ruleNameCt:'新規則',
    att:'-', passt:false, avgt:false,
    pass:'a', avg:'a',
    mediaEn:'', mediaCt:'',
    dl:'l', nameEn:'', nameCt:'', lnkEn:'', lnkCt:'',
    modUid: toStr(modUid),
});

const initEdReme = (modUid) =>  ({
    ruleNameEn:'New Rule', ruleNameCt:'新規則',
    //MSetId: toStr(r.MSetId),
    rmAB: 'a',
    rmMark: 50,
    rmLv: RemeLvSame,
    rmPass: 100,
    modUid: toIdStr(modUid),
});

export const pickSMByRemeLv = (remeLv, EQMetas, EQMetaPres, EQMetaPros) => {
    const remeSMIds = remeLv2SMIds(remeLv, EQMetas, EQMetaPres, EQMetaPros); 
    const qSMIdPairs = toUniIdAry(remeSMIds).map(splitSMId);
    const remeMSetIds = qSMIdPairs.map( sm => sm[0]);
//console.log({EQSum, remeSMIds, qSMIdPairs, remeMSetIds})
    return [qSMIdPairs, remeMSetIds];
};

export const remeLv2SMIds = (lv, EQMetas, EQMetaPres, EQMetaPros) => {
    return (lv === RemeLvPre)? EQMetaPres
    :(lv === RemeLvPro)? EQMetaPros
    :(lv === RemeLvSame)? EQMetas
    :toUniIdAry([].concat(toAry(EQMetas), toAry(EQMetaPres), toAry(EQMetaPros)))
};

const rem2metas = (EQMetas, EQMetaPres, EQMetaPros, reme) => {
    const MSId = toIdStr(reme?.MSetId);
    const srcMetas = remeLv2SMIds(reme?.rmLv, EQMetas, EQMetaPres, EQMetaPros);
    const ret = toUniIdAry(srcMetas).filter(sm => sm.startsWith(MSId+','));
    //console.log('rem2metas', {ret, MSId, lv});
    return ret;
};

const mixRemes = (remeM2d, edRemeMs, edRIdx) => {
    const ret = toUniIdAry([].concat(toAry(edRemeMs), ...toAry(remeM2d).map((ms, i) => (edRIdx === i)? [] : toAry(ms))));
    //console.log('mixRemes', edRIdx, {edRemeMs, remeM2d, ret});
    return ret;
};

export const useMemoAsync = (asyncFunc, deps, init) => {
    const [memo, setMemo] = useState(init);
    const asyncSet = async () => setMemo(await asyncFunc());
    useEffect(() => { 
//console.log('useMemoAsync', {deps});
        asyncSet(); 
    }, deps);
    return memo;
};


